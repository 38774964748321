import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../context';
import BaseColumn from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SupervisorNotes';
const SupervisorNotes = (props) => {
    const { updatePlanning, userPermissions } = useWeeklyPlanningPageContext((store) => ({
        updatePlanning: store.mainTable.advancedViewMode.operations.updatePlanning,
        userPermissions: store.commonData.userPermissions.data
    }));
    const doNotAllowUpdates = !userPermissions?.userCan.updateTheManagerColumns();
    return (React.createElement(BaseColumn, { planning: props.planning, handleSupervisorNotesUpdated: updatePlanning, doNotAllowUpdates: doNotAllowUpdates }));
};
export default SupervisorNotes;
