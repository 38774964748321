import { createFiltersSliceCreator } from './filters/slice';
export function createSimplifiedViewModeSlice(events) {
    return (set, get, ...args) => ({
        filteredContent: [],
        nonFilteredContent: [],
        filters: createFiltersSliceCreator(events)(set, get, ...args),
        setContent(content) {
            set((store) => {
                return {
                    mainTable: {
                        ...store.mainTable,
                        simplifiedViewMode: {
                            ...store.mainTable.simplifiedViewMode,
                            filteredContent: content,
                            nonFilteredContent: content
                        }
                    }
                };
            });
            events.contentSet.emit();
        }
    });
}
