import React, { useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ClassBox from './components/ClassBox';
import OptionsPopup from './components/OptionsPopup';
import Content from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/components/Content';
import ExpandButton from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/components/ExpandButton';
import { combineRefs } from '../../../../../../../../../../../../common/utils/refs';
import { useExpandButton } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/hooks/useExpandButton';
import { StyledHamburgerIcon, StyledHamburgerIconWrapper, StyledPopupAndHamburgerIconWrapper, StyledWrapper } from './styles';
const contentHeight = 300;
const Activity = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    const wrapperRef = useRef(null);
    const { canShowExpandButton } = useExpandButton({
        elementRef: wrapperRef,
        sequence: props.sequence,
        showButtonIfHeightIsGreaterThan: contentHeight
    });
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const isPlanningFinished = props.planning?.status?.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningFinished || isPlanningDeleted;
    function renderContent(draggableProvided) {
        const draggableProps = draggableProvided?.draggableProps ?? {};
        const dragHandleProps = draggableProvided?.dragHandleProps ?? {};
        const ref = draggableProvided
            ? combineRefs(draggableProvided.innerRef, wrapperRef)
            : wrapperRef;
        return (React.createElement(StyledWrapper, { ref: ref, ...draggableProps, "$addPaddingBottom": !canShowExpandButton },
            React.createElement(StyledPopupAndHamburgerIconWrapper, null,
                React.createElement(StyledHamburgerIconWrapper, { onClick: showPopup, ...dragHandleProps },
                    React.createElement(StyledHamburgerIcon, null)),
                React.createElement(OptionsPopup, { planning: props.planning, sequence: props.sequence, sequenceIndex: props.sequenceIndex, canBeDisplayed: canShowPopup, handleExternalClick: hidePopup })),
            React.createElement(Content, { sequence: props.sequence, maxHeight: contentHeight }),
            React.createElement(ClassBox, { sequence: props.sequence, planning: props.planning }),
            canShowExpandButton ? (React.createElement(ExpandButton, { sequence: props.sequence })) : null));
    }
    return isPlanningFinishedOrDeleted ? (renderContent()) : (React.createElement(Draggable, { draggableId: props.sequence.id, index: props.sequenceIndex }, (draggableProvided) => renderContent(draggableProvided)));
};
export default Activity;
