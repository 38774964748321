import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { Button, FilterGroup } from '../../styles';
const FilterSkill = () => {
    const filters = useWeeklyPlanningPageContext((store) => ({
        onlyEvaluativeSkills: store.mainTable.advancedViewMode.filters.onlyEvaluativeSkills,
        onlySkillsWithoutDidacticSequence: store.mainTable.advancedViewMode.filters
            .onlySkillsWithoutDidacticSequence,
        toggleOnlyEvaluativeSkills: store.mainTable.advancedViewMode.filters.toggleOnlyEvaluativeSkills,
        toggleOnlySkillsWithoutDidacticSequence: store.mainTable.advancedViewMode.filters
            .toggleOnlySkillsWithoutDidacticSequence
    }));
    return (React.createElement(FilterGroup, null,
        React.createElement("span", null, "Habilidades:"),
        React.createElement("div", null,
            React.createElement(Button, { "$isActive": filters.onlyEvaluativeSkills, onClick: filters.toggleOnlyEvaluativeSkills }, "Avaliativas"),
            React.createElement(Button, { "$isActive": filters.onlySkillsWithoutDidacticSequence, onClick: filters.toggleOnlySkillsWithoutDidacticSequence }, "Sem Sequ\u00EAncia"))));
};
export default FilterSkill;
