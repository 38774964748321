import { useWeeklyPlanningPageContext } from '../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
export function useSeparatePlanningFromGroup() {
    const planningGroup = useGroupContext((store) => store.group);
    const separate = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.operations.separateFromGroup);
    function separatePlanning(planning) {
        return new Promise((resolve, reject) => {
            const { plannings } = planningGroup;
            if (plannings.length > 1) {
                separate(planning);
                resolve();
            }
            else {
                reject();
            }
        });
    }
    return { separatePlanning };
}
