import { updateSlice } from './helpers';
function handleContentChanged(api) {
    api.setState((store) => updateSlice(store, {}));
}
export function setupEventHandlers(storeApi, mainTableEvents) {
    function handleEvent() {
        handleContentChanged(storeApi);
    }
    mainTableEvents.contentSet.subscribe(handleEvent);
    mainTableEvents.contentChanged.subscribe(handleEvent);
    mainTableEvents.viewModeChanged.subscribe(({ newViewMode }) => {
        if (newViewMode === 'simplified') {
            storeApi.setState((store) => updateSlice(store, {}));
        }
    });
}
