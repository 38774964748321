import { saveDisciplinesToStorage, saveGradeToStorage, saveSegmentToStorage } from './storage';
import { setupEventHandlers } from './eventHandlers';
import { getAvailableGrades, getAvailableDisciplines } from './availableComponents';
export function createBaseCurriculumComponentsSlice(params) {
    setupEventHandlers(params);
    return {
        availableGrades: [],
        availableSegments: [],
        availableDisciplines: [],
        selectedGrade: null,
        selectedSegment: null,
        selectedDisciplines: [],
        selectedGradeChanged(gradeId) {
            params.updateSlice((slice) => {
                saveGradeToStorage(gradeId);
                saveDisciplinesToStorage([]);
                const grades = params.getAllGrades();
                if (!grades || grades.length === 0)
                    return slice;
                const selectedGrade = grades.find((g) => g.id === gradeId);
                const availableDisciplines = getAvailableDisciplines(gradeId, params.getAllDisciplines());
                return {
                    selectedGrade,
                    selectedDisciplines: [],
                    availableDisciplines
                };
            });
        },
        selectedSegmentChanged(segmentId) {
            params.updateSlice((slice) => {
                saveGradeToStorage(null);
                saveDisciplinesToStorage([]);
                saveSegmentToStorage(segmentId);
                const segments = params.getAllSegments();
                if (!segments || segments.length === 0)
                    return slice;
                const selectedSegment = segments.find((s) => s.id === segmentId);
                const availableGrades = getAvailableGrades(segmentId, params.getAllGrades(), params.getAllDisciplines());
                return {
                    selectedSegment,
                    selectedGrade: null,
                    selectedDisciplines: [],
                    availableGrades
                };
            });
        },
        addToSelectedDisciplines(disciplineId) {
            params.updateSlice((slice) => {
                const disciplines = params.getAllDisciplines();
                if (!disciplines || disciplines.length === 0)
                    return slice;
                const discipline = disciplines.find((d) => d.id === disciplineId);
                const selectedDisciplines = [...slice.selectedDisciplines, discipline];
                saveDisciplinesToStorage(selectedDisciplines);
                return { selectedDisciplines };
            });
        },
        removeFromSelectedDisciplines(disciplineId) {
            params.updateSlice((slice) => {
                const selectedDisciplines = slice.selectedDisciplines.filter((d) => d.id !== disciplineId);
                saveDisciplinesToStorage(selectedDisciplines);
                return { selectedDisciplines };
            });
        }
    };
}
