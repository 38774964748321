import { createOperationsSlice } from './operations';
import { sortSkills } from './helpers';
export const createMainTableSlice = (set, get, ...args) => ({
    content: [],
    operations: createOperationsSlice(set, get, ...args),
    setContent(content) {
        set((store) => {
            return {
                mainTable: {
                    ...store.mainTable,
                    content: sortSkills(content)
                }
            };
        });
    }
});
