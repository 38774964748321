import { updateSlice } from './helpers';
import { setupEventHandlers } from './eventHandlers';
export function createFiltersSliceCreator(events) {
    return (set, _, api) => {
        setupEventHandlers(api, events);
        return {
            minimumClassroomsDate: null,
            maximumClassroomsDate: null,
            selectedClass: null,
            setMinimumClassroomsDate(date) {
                set((store) => updateSlice(store, { minimumClassroomsDate: date }));
            },
            setMaximumClassroomsDate(date) {
                set((store) => updateSlice(store, { maximumClassroomsDate: date }));
            },
            setSelectedClass(classId) {
                set((store) => {
                    const classes = store.commonData.classes.data;
                    const selectedClass = classes?.find((c) => c.id === classId);
                    return updateSlice(store, { selectedClass });
                });
            }
        };
    };
}
