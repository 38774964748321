import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import ClassroomSelectorMenu from '../../../../../../../../../../../../../../common/components/Basics/ClassroomSelectorMenu';
export const StyledWrapper = styled.div `
  margin-left: auto;

  position: relative;
`;
export const StyledBoxAndClassDateWrapper = styled.div `
  display: grid;
  gap: 3px;
`;
export const StyledClassDate = styled.span `
  text-align: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.gray400};
`;
export const StyledBox = styled.div `
  padding: 0 1px 0 8px;

  height: 24px;

  display: flex;
  column-gap: 2px;
  align-items: center;

  ${(props) => (props.$disabled ? '' : 'cursor: pointer;')}

  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${(props) => props.$color ?? props.theme.colors.gray200};
`;
export const StyledText = styled.span `
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;

  color: ${(props) => props.$color ?? props.theme.colors.gray700};
`;
export const StyledMenu = styled(ClassroomSelectorMenu) `
  z-index: 1;

  position: absolute;
  top: 100%;
  right: 0;
`;
export const StyledArrowIcon = styled(CampusIcon).attrs({
    name: 'selectArrowDown'
}) `
  display: block;

  stroke-width: 0;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
`;
