import { RequestStatus } from '@campus/commons';
import { errorHappened, loadingData, notAllCurriculumComponentsHaveBeenSelected } from '../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
export function getPlaceholder({ selectedGrade, selectedSegment, selectedDisciplines, fetchContentRequestStatus }) {
    if (!selectedGrade || !selectedSegment) {
        return notAllCurriculumComponentsHaveBeenSelected;
    }
    if (!selectedDisciplines || selectedDisciplines.length === 0) {
        return notAllCurriculumComponentsHaveBeenSelected;
    }
    if (fetchContentRequestStatus === RequestStatus.Loading) {
        return loadingData;
    }
    if (fetchContentRequestStatus === RequestStatus.Failed) {
        return errorHappened;
    }
    return null;
}
