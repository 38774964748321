import React from 'react';
import { useWeeklyPlanningPageAdvancedViewModeTableContext } from '../../context';
import { columns } from '../../columns';
import { StyledWrapper } from './styles';
function getTableScrollableArea() {
    const id = 'advanced-view-mode-table--scrollable-area';
    return document.querySelector('#' + id);
}
const TableHeader = () => {
    const { tableInstance } = useWeeklyPlanningPageAdvancedViewModeTableContext();
    return (React.createElement(StyledWrapper, { columns: columns, tableInstance: tableInstance, getContainerElement: getTableScrollableArea }));
};
export default TableHeader;
