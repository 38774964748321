import { useTheme } from 'styled-components';
import { RequestStatus } from '../../../../../../../../../../../../../../../common/types/dataFetching';
export function useStatusAndInfos() {
    const theme = useTheme();
    const statusAndInfos = {
        [RequestStatus.Loading]: {
            disabled: true,
            content: 'Atualizando...',
            color: theme.colors.blue500
        },
        [RequestStatus.Failed]: {
            disabled: true,
            content: 'Falha ao atualizar',
            color: theme.colors.error
        }
    };
    function getInfos(status) {
        return statusAndInfos[status];
    }
    return { getInfos };
}
