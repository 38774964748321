import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { Button } from '../../styles';
import { Container } from './styles';
const VisionSelector = () => {
    const viewMode = useWeeklyPlanningPageContext((store) => store.mainTable.viewMode);
    const setViewMode = useWeeklyPlanningPageContext((store) => store.mainTable.setViewMode);
    return (React.createElement(Container, null,
        React.createElement("span", null, "Vis\u00E3o:"),
        React.createElement("div", null,
            React.createElement(Button, { "$isActive": viewMode === 'simplified', onClick: () => setViewMode('simplified') }, "Simplificada"),
            React.createElement(Button, { "$isActive": viewMode === 'advanced', onClick: () => setViewMode('advanced') }, "Avan\u00E7ada"))));
};
export default VisionSelector;
