import styled from 'styled-components';
import { TableBodyCell } from '../../styles';
export const Column = styled(TableBodyCell) `
  padding: 9px;

  text-align: left;

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray3};
`;
