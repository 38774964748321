import SimplifiedViewModeTableActivity from './SimplifiedViewModeTableActivity';
export default class SimplifiedViewModeTablePlanningGroup {
    number;
    didacticSequenceActivities;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new SimplifiedViewModeTablePlanningGroup();
        instance.number = json['Group'];
        const activities = json['DidacticSequenceActivities'];
        instance.didacticSequenceActivities = Array.isArray(activities)
            ? activities.map((a) => {
                return SimplifiedViewModeTableActivity.fromJson(a);
            })
            : [];
        return instance;
    }
}
