import { RequestStatus } from '@campus/commons';
import { createBaseCommonDataSlice } from '../../../../common/stores/slices';
export function createCommonDataSlice(events) {
    return (set, get) => ({
        ...createBaseCommonDataSlice(events, (updater) => {
            set(({ commonData }) => ({
                commonData: { ...commonData, ...updater(commonData) }
            }));
        }),
        classes: { data: [], status: RequestStatus.NotStarted },
        stages: { data: [], status: RequestStatus.NotStarted },
        evaluationTypes: { data: [], status: RequestStatus.NotStarted },
        planningStatuses: { data: [], status: RequestStatus.NotStarted },
        availableClassrooms: { data: [], status: RequestStatus.NotStarted },
        userPermissions: { data: null, status: RequestStatus.NotStarted },
        setStages(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        stages: { ...store.commonData.stages, ...updates }
                    }
                };
            });
        },
        setEvaluationTypes(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        evaluationTypes: { ...store.commonData.evaluationTypes, ...updates }
                    }
                };
            });
        },
        setPlanningStatuses(statuses) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        planningStatuses: {
                            ...store.commonData.planningStatuses,
                            ...statuses
                        }
                    }
                };
            });
        },
        setAvailableClassrooms(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        availableClassrooms: {
                            ...store.commonData.availableClassrooms,
                            ...updates
                        }
                    }
                };
            });
        },
        setUserPermissions(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        userPermissions: {
                            ...store.commonData.userPermissions,
                            ...updates
                        }
                    }
                };
            });
        },
        setClasses(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        classes: { ...store.commonData.classes, ...updates }
                    }
                };
            });
        },
        getStages() {
            return get().commonData.stages.data;
        }
    });
}
