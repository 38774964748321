import { updateSlice } from './helpers';
function handleContentChanged(api) {
    api.setState((store) => {
        return updateSlice(store, {
            stage: null,
            justStartedSkills: false,
            justUsedObjectives: false
        });
    });
}
export function setupEventHandlers(storeApi, mainTableEvents) {
    function handleContentEvent() {
        handleContentChanged(storeApi);
    }
    mainTableEvents.contentSet.subscribe(handleContentEvent);
    mainTableEvents.contentChanged.subscribe(handleContentEvent);
    mainTableEvents.viewModeChanged.subscribe(({ newViewMode }) => {
        if (newViewMode === 'advanced') {
            storeApi.setState((store) => updateSlice(store, {}));
        }
    });
}
