import React from 'react';
import { Discipline, ThemeUnit } from '../../../../../../../common/components/PageRelated/Body/components/Table/Columns';
import Skill from './components/Columns/Skill';
import AnalystNotes from './components/Columns/AnalystNotes';
import TeacherNotes from './components/Columns/TeacherNotes';
import SupervisorNotes from './components/Columns/SupervisorNotes';
import DidacticSequence from './components/Columns/DidacticSequence';
import KnowledgeObjects from './components/Columns/KnowledgeObjects';
import LearningObjectives from './components/Columns/LearningObjectives';
import ObservedLags from './components/Columns/ObservedLags';
export const columns = [
    {
        id: 'disciplineName',
        Header: 'Componente Curricular',
        centered: true,
        sticky: 'left',
        borderOn: 'right',
        width: 190,
        minWidth: 190,
        accessor: (planning) => planning.areaSkill?.discipline,
        Cell: ({ value }) => React.createElement(Discipline, { discipline: value })
    },
    {
        id: 'themeUnit',
        Header: 'UT',
        centered: true,
        sticky: 'left',
        borderOn: 'right',
        width: 47,
        minWidth: 47,
        accessor: (planning) => planning.areaSkill?.themeUnit,
        Cell: ({ value }) => React.createElement(ThemeUnit, { themeUnit: value })
    },
    {
        id: 'skill',
        Header: 'Habilidade',
        sticky: 'left',
        borderOn: 'right',
        centered: true,
        doNotAddRightBorderToCell: true,
        width: 290,
        minWidth: 290,
        accessor: (planning) => planning,
        Cell: ({ row, draggableProvided }) => (React.createElement(Skill, { row: row, draggableProvided: draggableProvided }))
    },
    {
        id: 'learningObjectives',
        Header: 'Objetivos de Aprendizagem',
        borderOn: 'right',
        centered: true,
        width: 227,
        minWidth: 227,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(LearningObjectives, { planning: value })
    },
    {
        id: 'knowledgeObjects',
        Header: 'Objetos de Conhecimento',
        borderOn: 'right',
        width: 217,
        minWidth: 217,
        centered: true,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(KnowledgeObjects, { planning: value })
    },
    {
        id: 'didaticSequence',
        Header: 'Sequência Didática',
        borderOn: 'right',
        centered: true,
        width: 369,
        minWidth: 369,
        spanAcrossAllRows: true,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(DidacticSequence, { planning: value })
    },
    {
        id: 'teacherObservation',
        Header: 'Observações do Professor',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(TeacherNotes, { planning: value })
    },
    {
        id: 'observedLags',
        Header: 'Defasagens Observadas',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(ObservedLags, { planning: value })
    },
    {
        id: 'analystNotes',
        Header: 'Observações do Analista',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(AnalystNotes, { planning: value })
    },
    {
        id: 'supervisorObservation',
        Header: 'Observações do Supervisor',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235,
        accessor: (planning) => planning,
        Cell: ({ value }) => React.createElement(SupervisorNotes, { planning: value })
    }
];
