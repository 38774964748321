import { useGroupContext } from '../../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { addDidacticSequenceAndRelatedClassrooms } from '../../../../../../../../../../../../../../../../services/agregators';
export function useDuplicateActivity() {
    const addActivity = useGroupContext((store) => store.sequenceOperations.add);
    function duplicateActivity(sequence, sequenceIndex, planning) {
        return new Promise((resolve, reject) => {
            if (sequence.isDeleted)
                reject();
            addDidacticSequenceAndRelatedClassrooms({
                planningId: planning.id,
                areClassroomsUnforeseen: false,
                classrooms: sequence?.classrooms,
                description: sequence?.description,
                impactTheMDC: sequence?.impactTheMDC,
                evaluationTypeId: sequence?.evaluationType?.id
            })
                .then((response) => {
                addActivity(response.data, sequenceIndex + 1);
                resolve();
            })
                .catch(reject);
        });
    }
    return { duplicateActivity };
}
