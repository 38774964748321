import React from 'react';
import DeletionModal from '../../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/components/DeletionModal';
import { usePortal } from '../../../../../../../../../../../../../../../common/contexts/portals';
import { useRemoveActivity } from './useRemoveActivity';
import { times } from '../../../../../../../../../../../../../../../common/constants';
export function useControlDeletionModal() {
    const { create, destroy } = usePortal(document.body);
    const { removeActivity } = useRemoveActivity();
    function handleConfirmButtonClicked(sequence, planning) {
        return () => {
            return new Promise((resolve, reject) => {
                removeActivity(sequence, planning)
                    .then(() => {
                    destroy({ afterMillis: times.toHideAModalOrPopupOnSuccessQuickly });
                    resolve();
                })
                    .catch(reject);
            });
        };
    }
    function createModal(sequence, planning) {
        create(React.createElement(DeletionModal, { canBeDisplayed: true, title: 'Confirmação de remoção', description: 'Deseja realmente remover a atividade?', handleExternalClick: destroy, handleCancelButtonClicked: destroy, handleConfirmButtonClicked: handleConfirmButtonClicked(sequence, planning) }));
    }
    return { createModal };
}
