import React, { useMemo } from 'react';
import { useSticky } from 'react-table-sticky';
import { useBlockLayout, useTable } from 'react-table';
import { DragDropContext } from 'react-beautiful-dnd';
import TablePlaceholder from '../../../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { useHideDisciplineColumn } from '../../../../../../../common/components/PageRelated/Body/components/Table/hooks/useHideDisciplineColumn';
import { WeeklyPlanningPageAdvancedViewModeTableContext } from './context';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import { columns } from './columns';
import { noContent } from './placeholders';
import { useDragAndDrop } from './hooks/useDragAndDrop';
import { StyledWrapper, StyledScrollableArea } from './styles';
export const AdvancedViewModeTable = () => {
    const content = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filteredContent);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    const plannings = useMemo(() => {
        return content.flatMap((group) => group.plannings);
    }, [content]);
    const tableInstance = useTable({
        columns,
        data: plannings,
        initialState: { hiddenColumns: ['disciplineName'] },
        getRowId: (planning) => planning.id
    }, useBlockLayout, useSticky);
    useHideDisciplineColumn({
        tableInstance,
        selectedDisciplines,
        columnId: 'disciplineName'
    });
    const dragAndDropRelated = useDragAndDrop();
    if (plannings.length === 0) {
        return React.createElement(TablePlaceholder, { content: noContent });
    }
    return (React.createElement(WeeklyPlanningPageAdvancedViewModeTableContext.Provider, { value: { columns, tableInstance } },
        React.createElement(DragDropContext, { onDragEnd: dragAndDropRelated.onDragEnd },
            React.createElement(StyledWrapper, { ...tableInstance.getTableProps() },
                React.createElement(StyledScrollableArea, { id: "advanced-view-mode-table--scrollable-area" },
                    React.createElement(TableHeader, null),
                    React.createElement(TableBody, null))))));
};
