import { useEffect, useState } from 'react';
import { toast } from '@campus/components';
import { RequestStatus, usePagination } from '@campus/commons';
import { getEmptyTeacherClasses } from '../../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../../context';
const pageSize = 10;
export function useFetchTableContent({ teacherId, filters }) {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const [data, setData] = useState(null);
    const { managerId } = useLaunchReportContext();
    const { pageIndex, setPageIndex, numberOfPages, setNumberOfPages, switchPageIndexToThePreviuosOne } = usePagination({ pageSize });
    useEffect(() => {
        async function fetchData() {
            setRequestStatus(RequestStatus.Loading);
            const response = await getEmptyTeacherClasses({
                ...filters,
                managerId,
                teacherId,
                pageSize,
                pageIndex
            });
            if (response.data) {
                setNumberOfPages(response.data.numberOfPages);
                setData(response.data.disciplines);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
                toast.error('Falha ao buscar lista de aulas vazias');
                switchPageIndexToThePreviuosOne();
            }
        }
        fetchData();
    }, [
        filters,
        managerId,
        teacherId,
        pageIndex,
        setNumberOfPages,
        switchPageIndexToThePreviuosOne
    ]);
    return { data, requestStatus, pageIndex, setPageIndex, numberOfPages };
}
