import { stringify } from 'qs';
import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import SimplifiedViewModeTablePlanningGroup from '../models/SimplifiedViewModeTablePlanningGroup';
const endpoints = {
    getTableContent: '/pedagogicalplanning/simplified'
};
export async function getTableContent({ abortSignal, gradeId, segmentId, disciplineIds }) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getTableContent, {
            signal: abortSignal,
            params: {
                GradeId: gradeId,
                SegmentId: segmentId,
                DisciplineIds: disciplineIds
            },
            paramsSerializer(params) {
                return stringify(params, { arrayFormat: 'indices', skipNulls: true });
            }
        });
        const items = Array.isArray(response.data)
            ? response.data.map((item) => SimplifiedViewModeTablePlanningGroup.fromJson(item))
            : [];
        return Response.fromData(items, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
