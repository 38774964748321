import React, { useCallback, useState } from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../pages/WeeklyPlanning/context';
import { useHandlers } from '../../../../../../../../../pages/common/components/PageRelated/Body/components/Header/components/Buttons/ExportButton/components/Popup/hooks/useHandlers';
import { MenuButton, MenuActionsPopupStyled } from './styles';
const MenuActions = () => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const { components, content, selectedClass } = useWeeklyPlanningPageContext((store) => ({
        components: store.curriculumComponents,
        content: store.mainTable.advancedViewMode.filteredContent,
        selectedClass: store.mainTable.advancedViewMode.filters.selectedClass
    }));
    const { generateClassDiaryReport } = useHandlers({
        selectedDisciplines: components.selectedDisciplines,
        selectedGrade: components.selectedGrade,
        selectedSegment: components.selectedSegment,
        skills: [],
        type: 'pdf'
    });
    const handleGenerateClassDiaryReportExport = useCallback(() => generateClassDiaryReport(content, components.selectedDisciplines[0]?.id, selectedClass, components.selectedGrade?.id, components.selectedSegment?.id), [
        components.selectedDisciplines,
        content,
        generateClassDiaryReport,
        selectedClass,
        components.selectedSegment,
        components.selectedGrade
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuButton, { onClick: () => setCanShowPopup(true) }, "Exportar"),
        React.createElement(MenuActionsPopupStyled, { generateClassDiaryReport: handleGenerateClassDiaryReportExport, canBeDisplayed: canShowPopup, hidePopup: () => setCanShowPopup(false) })));
};
export default MenuActions;
