import { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { getGrades } from '../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../context';
export function useFetchGrades() {
    const [data, setData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { managerId } = useLaunchReportContext();
    useEffect(() => {
        async function fetchData() {
            setRequestStatus(RequestStatus.Loading);
            const response = await getGrades({ managerId });
            if (response.data) {
                setData(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
    }, [managerId]);
    return { data, requestStatus };
}
