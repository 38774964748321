import { useState, useRef, useCallback } from 'react';
import { useIsMounted } from '../../../../../../../../../hooks';
import { RequestStatus } from '../../../../../../../../../types/dataFetching';
import { times } from '../../../../../../../../../constants';
export function useFetchTableContentCommon() {
    const [hasMorePages, setHasMorePages] = useState(true);
    const [content, setContent] = useState([]);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const pageIndexRef = useRef(0);
    const { isMounted } = useIsMounted();
    const pageIndex = useCallback(() => {
        return pageIndexRef.current;
    }, []);
    const resetContentAndPageIndex = useCallback(() => {
        setContent([]);
        pageIndexRef.current = 0;
    }, []);
    const resetStatusAfterDelay = useCallback(() => {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }, [isMounted]);
    const handleFetchStarted = useCallback(() => {
        setRequestStatus(RequestStatus.Loading);
    }, []);
    const handleFetchSucceeded = useCallback((response) => {
        if (!response.data)
            return;
        setRequestStatus(RequestStatus.Succeeded);
        setHasMorePages(response.data.hasNextPage);
        setContent((content) => [...content, ...response.data.items]);
    }, []);
    const handleFetchFailed = useCallback((response) => {
        if (!response?.isCancellation()) {
            setRequestStatus(RequestStatus.Failed);
        }
    }, []);
    const canFetchMoreContent = useCallback(() => {
        return hasMorePages && requestStatus !== RequestStatus.Loading;
    }, [hasMorePages, requestStatus]);
    const advancePageIndex = useCallback(() => {
        pageIndexRef.current += 1;
    }, []);
    return {
        content,
        requestStatus,
        pageIndex,
        resetContentAndPageIndex,
        resetStatusAfterDelay,
        handleFetchStarted,
        handleFetchSucceeded,
        handleFetchFailed,
        canFetchMoreContent,
        advancePageIndex
    };
}
