import React, { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { times } from '../../../constants';
import { useIsMounted } from '../../../hooks';
import { ActionDescription, Buttons, Modal, Title } from './styles';
const firstButtonContent = {
    [RequestStatus.NotStarted]: 'Adicionar a aula e reorganizar as aulas subsequentes',
    [RequestStatus.Loading]: 'Executando ações...',
    [RequestStatus.Succeeded]: 'Ações concluídas com sucesso!',
    [RequestStatus.Failed]: 'Falha ao executar ações'
};
const secondButtonContent = {
    [RequestStatus.NotStarted]: 'Criar uma aula em duplicidade',
    [RequestStatus.Loading]: 'Criando aula em duplicidade...',
    [RequestStatus.Succeeded]: 'Aula criada com sucesso!',
    [RequestStatus.Failed]: 'Falha ao criar aula'
};
export const ClassroomActionsModal = ({ show, hide, onCancel, onJustAddActivity, onAddActivityAndUpdateOtherOnes }) => {
    const { isMounted } = useIsMounted();
    const [firstButtonStatus, setFirstButtonStatus] = useState(RequestStatus.NotStarted);
    const [secondButtonStatus, setSecondButtonStatus] = useState(RequestStatus.NotStarted);
    const disableFirstTwoButtons = firstButtonStatus !== RequestStatus.NotStarted ||
        secondButtonStatus !== RequestStatus.NotStarted;
    useEffect(() => {
        setFirstButtonStatus(RequestStatus.NotStarted);
        setSecondButtonStatus(RequestStatus.NotStarted);
    }, [show]);
    function hideAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                hide();
            }
        }, times.toHideAModalOrPopupOnSuccess);
    }
    function resetStatusAfterDelay(setStatus) {
        setTimeout(() => {
            if (isMounted()) {
                setStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    async function withStatusTracking(setStatus, run) {
        setStatus(RequestStatus.Loading);
        try {
            await run();
            setStatus(RequestStatus.Succeeded);
            hideAfterDelay();
        }
        catch {
            setStatus(RequestStatus.Failed);
            resetStatusAfterDelay(setStatus);
        }
    }
    async function onFirstButtonClick() {
        await withStatusTracking(setFirstButtonStatus, onAddActivityAndUpdateOtherOnes);
    }
    async function onSecondButtonClick() {
        await withStatusTracking(setSecondButtonStatus, onJustAddActivity);
    }
    function onThirdButtonClick() {
        onCancel();
        hide();
    }
    return (React.createElement(Modal, { show: show, onHide: hide },
        React.createElement(Title, null, "Reordena\u00E7\u00E3o das aulas"),
        React.createElement(ActionDescription, null, "Aten\u00E7\u00E3o! Voc\u00EA est\u00E1 adicionando uma aula em duplicidade. Clique na op\u00E7\u00E3o que voc\u00EA deseja realizar:"),
        React.createElement(Buttons, null,
            React.createElement("button", { onClick: onFirstButtonClick, disabled: disableFirstTwoButtons }, firstButtonContent[firstButtonStatus]),
            React.createElement("button", { onClick: onSecondButtonClick, disabled: disableFirstTwoButtons }, secondButtonContent[secondButtonStatus]),
            React.createElement("button", { onClick: onThirdButtonClick }, "Cancelar a\u00E7\u00E3o"))));
};
