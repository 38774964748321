import { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { getAllSegments } from '../../../../services/segment';
export function useFetchSegments() {
    const [data, setData] = useState([]);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            setRequestStatus(RequestStatus.Loading);
            const response = await getAllSegments(null, {
                abortSignal: abortController.signal
            });
            if (response.data) {
                setData(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
        return () => abortController.abort();
    }, []);
    return { data, requestStatus };
}
