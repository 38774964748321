import { useEffect } from 'react';
import { RequestStatus } from '../../../../../types/dataFetching';
import { getEvaluationTypesForDisciplines } from '../../../../../../../services/evaluationType';
export function useFetchEvaluationTypes(disciplines, store, abortSignal) {
    useEffect(() => {
        if (!store)
            return;
        const { setEvaluationTypes } = store.getState();
        async function fetchData() {
            if (!disciplines?.length)
                return;
            setEvaluationTypes([], RequestStatus.Loading);
            const disciplineIds = disciplines.map((d) => d.id);
            const response = await getEvaluationTypesForDisciplines({
                abortSignal,
                disciplineIds
            });
            if (response.data) {
                setEvaluationTypes(response.data, RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setEvaluationTypes([], RequestStatus.Failed);
            }
        }
        fetchData();
    }, [abortSignal, disciplines, store]);
}
