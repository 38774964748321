import React from 'react';
import { useGroupContext } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import Stages from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/Stages';
import Classes from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/Classes';
import { StyledHorizontalSeparator } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/common/styles';
const Infos = (props) => {
    const sequence = useGroupContext((store) => store.group.didacticSequence);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledHorizontalSeparator, null),
        React.createElement("div", null,
            React.createElement(Stages, { areaSkill: props.areaSkill }),
            React.createElement(Classes, { sequences: sequence }))));
};
export default Infos;
