import styled from 'styled-components';
import { TableBodyCell } from '../../styles';
export const Column = styled(TableBodyCell) `
  padding: 9px 10px;

  text-align: center;

  background: rgba(242, 242, 242, 0.5);

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray3};
`;
