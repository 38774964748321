import React from 'react';
import { Column } from './styles';
function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
}
export const ClassDate = ({ className, date }) => {
    const formattedDate = date ? formatDate(date) : '';
    return React.createElement(Column, { className: className }, formattedDate);
};
