import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { getReportContent } from '../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../context';
import { debounce } from '../../../../common/utils/debounce';
const pageSize = 20;
export function useFetchTableContent() {
    const [data, setData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const pageIndex = useRef(0);
    const { managerId, events, getFilters } = useLaunchReportContext();
    const fetchContent = useCallback(async () => {
        setRequestStatus(RequestStatus.Loading);
        const filters = getFilters();
        const response = await getReportContent({
            ...filters,
            managerId,
            pageSize,
            pageIndex: pageIndex.current
        });
        if (response.data) {
            setData(response.data.teachers);
            setRequestStatus(RequestStatus.Succeeded);
            events.table.dataFetched.emit({
                numberOfPages: response.data.numberOfPages,
                idsOfAllTeachers: response.data.idsOfAllTeachers
            });
        }
        else if (!response.isCancellation()) {
            setRequestStatus(RequestStatus.Failed);
            toast.error('Falha ao buscar conteúdo');
            events.table.dataFetchFailed.emit({ pageIndex: pageIndex.current });
        }
    }, [
        managerId,
        getFilters,
        events.table.dataFetched,
        events.table.dataFetchFailed
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchContent = useCallback(debounce(fetchContent, 1000), [
        fetchContent
    ]);
    useEffect(() => {
        fetchContent();
    }, [fetchContent]);
    useEffect(() => {
        return events.table.pageIndexChanged.subscribe((payload) => {
            if (!payload)
                return;
            pageIndex.current = payload.newIndex;
            debouncedFetchContent();
        });
    }, [events.table.pageIndexChanged, debouncedFetchContent]);
    useEffect(() => {
        function handler() {
            pageIndex.current = 0;
            debouncedFetchContent();
        }
        const unsubscribe1 = events.filters.gradeIdChanged.subscribe(handler);
        const unsubscribe2 = events.filters.disciplineIdsChanged.subscribe(handler);
        const unsubscribe3 = events.filters.classIdsChanged.subscribe(handler);
        const unsubscribe4 = events.filters.startDateChanged.subscribe(handler);
        const unsubscribe5 = events.filters.endDateChanged.subscribe(handler);
        return () => {
            unsubscribe1();
            unsubscribe2();
            unsubscribe3();
            unsubscribe4();
            unsubscribe5();
        };
    }, [
        events.filters.gradeIdChanged,
        events.filters.disciplineIdsChanged,
        events.filters.classIdsChanged,
        events.filters.startDateChanged,
        events.filters.endDateChanged,
        debouncedFetchContent
    ]);
    return { data, requestStatus };
}
