import { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { getFilteredClasses } from '../../../../services/class';
export function useFetchFilterClasses(props) {
    const [data, setData] = useState([]);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            if (!props.selectedGrade)
                return;
            if (!props.selectedDisciplines?.length)
                return;
            setRequestStatus(RequestStatus.Loading);
            const response = await getFilteredClasses({
                abortSignal: abortController.signal,
                gradeId: props.selectedGrade.id,
                disciplineIds: props.selectedDisciplines.map((d) => d.id)
            });
            if (response.data) {
                setData(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
        return () => abortController.abort();
    }, [props.selectedDisciplines, props.selectedGrade]);
    return { data, requestStatus };
}
