import { getLastSelectedDisciplines, getLastSelectedGrade, getLastSelectedSegment } from './lastSelections';
import { getAvailableSegments, getAvailableGrades, getAvailableDisciplines } from './availableComponents';
function handleCommonDataEvent(params) {
    params.updateSlice(() => {
        const segments = params.getAllSegments();
        const grades = params.getAllGrades();
        const disciplines = params.getAllDisciplines();
        const selectedSegment = getLastSelectedSegment(segments);
        const selectedGrade = getLastSelectedGrade(grades);
        const selectedDisciplines = getLastSelectedDisciplines(disciplines);
        const availableSegments = getAvailableSegments(segments, grades);
        const availableGrades = getAvailableGrades(selectedSegment?.id, grades, disciplines);
        const availableDisciplines = getAvailableDisciplines(selectedGrade?.id, disciplines);
        return {
            selectedSegment,
            selectedGrade,
            selectedDisciplines,
            availableGrades,
            availableSegments,
            availableDisciplines
        };
    });
}
export function setupEventHandlers(params) {
    function handleEvent() {
        handleCommonDataEvent(params);
    }
    params.commonDataEvents.segmentsChanged.subscribe(handleEvent);
    params.commonDataEvents.gradesChanged.subscribe(handleEvent);
    params.commonDataEvents.disciplinesChanged.subscribe(handleEvent);
}
