import { getDistinct } from '../../../utils/arrays';
export function getAvailableSegments(segments, grades) {
    if (!segments || segments.length === 0)
        return [];
    if (!grades || grades.length === 0)
        return [];
    return segments.filter((s) => {
        return grades.some((g) => g.segment?.id === s.id);
    });
}
export function getAvailableGrades(segmentId, grades, disciplines) {
    if (!segmentId)
        return [];
    if (!grades || grades.length === 0)
        return [];
    if (!disciplines || disciplines.length === 0)
        return [];
    return grades.filter((g) => {
        return (g.segment?.id === segmentId && disciplines.some((d) => d.gradeId === g.id));
    });
}
export function getAvailableDisciplines(gradeId, disciplines) {
    if (!gradeId)
        return [];
    if (!disciplines || disciplines.length === 0)
        return;
    return getDistinct(disciplines.filter((d) => d.gradeId === gradeId), (d) => d.id);
}
