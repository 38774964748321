import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../context';
export function useDisable(props) {
    const userPermissions = useWeeklyPlanningPageContext((store) => store.commonData.userPermissions.data);
    const tableContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filteredContent);
    const isPlanningDeleted = props.planning.status?.isDeleted() ?? false;
    const isPlanningFinished = props.planning.status?.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningFinished || isPlanningDeleted;
    const userNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    const disableRemove = userNotAllowedToUpdate || isPlanningDeleted;
    const disableDuplicateAndAddToBlock = userNotAllowedToUpdate || isPlanningFinishedOrDeleted;
    const isTheOnlyPlanningOnTable = tableContent.length === 1 &&
        tableContent[0].plannings.length === 1 &&
        tableContent[0].plannings[0].id === props.planning.id;
    const disableMoveAndSeparate = userNotAllowedToUpdate ||
        isPlanningFinishedOrDeleted ||
        isTheOnlyPlanningOnTable;
    return {
        remove: disableRemove,
        move: disableMoveAndSeparate,
        separate: disableMoveAndSeparate,
        duplicate: disableDuplicateAndAddToBlock,
        addToBlock: disableDuplicateAndAddToBlock
    };
}
