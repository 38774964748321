import { useWeeklyPlanningPageContext } from '../../../../../../../../../../context';
import { duplicatePlanning as duplicate } from '../../../../../../../../../../../../services/agregators';
export function useDuplicatePlanning() {
    const { addPlanningOperation, selectedGrade, selectedSegment } = useWeeklyPlanningPageContext((store) => ({
        addPlanningOperation: store.mainTable.advancedViewMode.operations.add,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment
    }));
    function duplicatePlanning(planning, index) {
        return new Promise((resolve, reject) => {
            if (!selectedGrade)
                reject();
            if (!selectedSegment)
                reject();
            const newPlanningIndex = index + 1;
            duplicate({ planning, newIndex: newPlanningIndex })
                .then((response) => {
                addPlanningOperation(response.data, newPlanningIndex);
                resolve();
            })
                .catch(reject);
        });
    }
    return { duplicatePlanning };
}
