import { updatePlanning as update } from '../../../../../../../services/pedagogicalPlanning';
export function updateContentInStore(store, newContent) {
    return {
        mainTable: {
            ...store.mainTable,
            advancedViewMode: {
                ...store.mainTable.advancedViewMode,
                filteredContent: newContent,
                nonFilteredContent: newContent
            }
        }
    };
}
export async function updatePlanning(planning, updates) {
    await update({
        id: planning.id,
        stageId: planning.stage?.id,
        statusId: planning.status?.id,
        analystNotes: planning.analystNotes,
        observedLags: planning.observedLags,
        teacherNotes: planning.teacherNotes,
        supervisorNotes: planning.supervisorNotes,
        order: updates?.newIndex ?? planning.order,
        group: updates?.newGroup ?? planning.associatedGroup
    });
}
export function updateIndicesOfGroupsAndTheirPlannings(groups) {
    let planningIndex = 0;
    for (const [i, newGroup] of groups.entries()) {
        newGroup.number = i;
        for (let j = 0; j < newGroup.plannings.length; ++j) {
            const newPlanning = newGroup.plannings[j];
            if (newPlanning.associatedGroup !== i ||
                newPlanning.order !== planningIndex) {
                newPlanning.associatedGroup = i;
                newPlanning.order = planningIndex;
                updatePlanning(newPlanning, {
                    newGroup: i,
                    newIndex: planningIndex
                });
            }
            planningIndex++;
        }
    }
}
