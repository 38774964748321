import React, { Fragment } from 'react';
import TablePlaceholder from '../../../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { noContent } from './placeholders';
import { ClassDate, ClassName, ClassNumber, MethodologyAndTeachingResources } from './components';
import { Container, Table, TableBody, TableBodyRow, TableHeader, TableHeaderCell, TableHeaderRow, TableBodyRowSpacer, ScrollableArea } from './styles';
function renderActivities(activities) {
    return activities.map((activity, activityIndex) => {
        return activity.classrooms.map((classroom, classroomIndex) => {
            return (React.createElement(TableBodyRow, { key: activity.id + '-' + classroom.id, "$isInTheFirstActivity": activityIndex === 0, "$isInTheLastActivity": activityIndex === activities.length - 1, "$isTheFirstRowInGroup": activityIndex === 0 && classroomIndex === 0, "$isTheLastRowInGroup": activityIndex === activities.length - 1 &&
                    classroomIndex === activity.classrooms.length - 1 },
                React.createElement(ClassDate, { className: "first-cell", date: classroom.date }),
                React.createElement(ClassNumber, { number: classroom.number }),
                React.createElement(ClassName, { name: classroom.class.name }),
                classroomIndex === 0 ? (React.createElement(MethodologyAndTeachingResources, { activity: activity, className: "last-cell" })) : null));
        });
    });
}
function renderGroups(content) {
    return content.map((group, index) => {
        return (React.createElement(Fragment, { key: group.number },
            renderActivities(group.didacticSequenceActivities),
            index === content.length - 1 ? null : React.createElement(TableBodyRowSpacer, null)));
    });
}
const SimplifiedViewModeTable = () => {
    const content = useWeeklyPlanningPageContext((store) => store.mainTable.simplifiedViewMode.filteredContent);
    if (content.length === 0) {
        return React.createElement(TablePlaceholder, { content: noContent });
    }
    return (React.createElement(Container, null,
        React.createElement(ScrollableArea, null,
            React.createElement(Table, null,
                React.createElement(TableHeader, null,
                    React.createElement(TableHeaderRow, null,
                        React.createElement(TableHeaderCell, null, "Data"),
                        React.createElement(TableHeaderCell, null, "N\u00FAmero"),
                        React.createElement(TableHeaderCell, null, "Turma"),
                        React.createElement(TableHeaderCell, null, "Metodologia e Recursos Did\u00E1ticos"))),
                React.createElement(TableBody, null, renderGroups(content))))));
};
export default SimplifiedViewModeTable;
