import { updateSlice } from './helpers';
import { setupEventHandlers } from './eventHandlers';
export function createFiltersSliceCreator(events) {
    return (set, _, api) => {
        setupEventHandlers(api, events);
        return {
            stage: null,
            justStartedSkills: false,
            justUsedObjectives: false,
            minimumClassroomsDate: null,
            maximumClassroomsDate: null,
            selectedClass: null,
            onlyEvaluativeSkills: false,
            onlySkillsWithoutDidacticSequence: false,
            setStage(stage) {
                set((store) => updateSlice(store, { stage }));
            },
            setJustStartedSkills(isSet) {
                set((store) => updateSlice(store, { justStartedSkills: isSet }));
            },
            setJustUsedObjectives(isSet) {
                set((store) => updateSlice(store, { justUsedObjectives: isSet }));
            },
            setMinimumClassroomsDate(date) {
                set((store) => updateSlice(store, { minimumClassroomsDate: date }));
            },
            setMaximumClassroomsDate(date) {
                set((store) => updateSlice(store, { maximumClassroomsDate: date }));
            },
            setSelectedClass(classId) {
                set((store) => {
                    const classes = store.commonData.classes.data;
                    const selectedClass = classes?.find((c) => c.id === classId);
                    return updateSlice(store, { selectedClass });
                });
            },
            toggleOnlyEvaluativeSkills() {
                set((store) => {
                    const { filters } = store.mainTable.advancedViewMode;
                    const onlyEvaluativeSkills = !filters.onlyEvaluativeSkills;
                    return updateSlice(store, { onlyEvaluativeSkills });
                });
            },
            toggleOnlySkillsWithoutDidacticSequence() {
                set((store) => {
                    const { filters } = store.mainTable.advancedViewMode;
                    const onlySkillsWithoutDidacticSequence = !filters.onlySkillsWithoutDidacticSequence;
                    return updateSlice(store, { onlySkillsWithoutDidacticSequence });
                });
            }
        };
    };
}
