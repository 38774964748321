import { useEffect, useState } from 'react';
import { useAuthority } from '@campus/auth';
import { RequestStatus, useUserId } from '@campus/commons';
import { getUserPermissions } from '../../../../services/userPermissions';
export function useFetchUserPermissions(props) {
    const { currentContext } = useAuthority();
    const { contextType } = currentContext.person;
    const { teacherId, managerId } = useUserId(contextType);
    const [data, setData] = useState(null);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            if (!props.selectedGrade)
                return;
            if (!props.selectedDisciplines?.length)
                return;
            setRequestStatus(RequestStatus.Loading);
            const response = await getUserPermissions({
                abortSignal: abortController.signal,
                teacherId,
                managerId,
                loggedAs: contextType,
                gradeId: props.selectedGrade.id,
                disciplineIds: props.selectedDisciplines.map((d) => d.id)
            });
            if (response.data) {
                setData(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
        return () => abortController.abort();
    }, [
        contextType,
        managerId,
        teacherId,
        props.selectedDisciplines,
        props.selectedGrade
    ]);
    return { data, requestStatus };
}
