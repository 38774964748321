import PedagogicalPlanningGroup from '../../../../../../models/PedagogicalPlanningGroup';
import { getGroupIndex, getGroupAndPlanningIndices, updateContentInStore } from './helpers';
export function createOperationsSliceCreator(events) {
    return (set, get) => {
        return {
            getGroupFromNonFilteredContent(groupNumber) {
                const content = get().mainTable.nonFilteredContent;
                return content.find((g) => g.number === groupNumber);
            },
            updateGroup(updatedGroup) {
                if (!updatedGroup)
                    return;
                set((store) => {
                    const firstPlanning = updatedGroup.plannings?.at(0);
                    const index = getGroupIndex(store, firstPlanning?.associatedGroup);
                    if (index === null)
                        return store;
                    const content = store.mainTable.nonFilteredContent;
                    const oldGroup = content[index];
                    if (oldGroup.number !== updatedGroup.number)
                        return store;
                    const newContent = [...content];
                    newContent[index] = updatedGroup;
                    return updateContentInStore(store, newContent);
                });
                events.contentChanged.emit();
            },
            updatePlanning(updatedPlanning) {
                set((store) => {
                    const indices = getGroupAndPlanningIndices(store, updatedPlanning);
                    if (indices.groupIndex === null)
                        return store;
                    if (indices.planningIndex === null)
                        return store;
                    const newContent = [...store.mainTable.nonFilteredContent];
                    const oldGroup = newContent[indices.groupIndex];
                    const newGroup = new PedagogicalPlanningGroup();
                    newGroup.number = oldGroup.number;
                    newGroup.didacticSequence = oldGroup.didacticSequence;
                    newGroup.plannings = [...oldGroup.plannings];
                    newGroup.plannings[indices.planningIndex] = updatedPlanning;
                    newContent[indices.groupIndex] = newGroup;
                    return updateContentInStore(store, newContent);
                });
                events.contentChanged.emit();
            }
        };
    };
}
