import React, { useState } from 'react';
import { getMonthRange, getWeekRange } from '../../../../../../../common/utils/dates';
import FilterSkillsButton from '../../../../../../../common/components/FilterSkillsButton';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { Button, FilterGroup } from '../../styles';
const FilterDate = () => {
    const [activeFilter, setActiveFilter] = useState('allTime');
    const minimumClassroomsDate = useWeeklyPlanningPageContext((store) => {
        return store.mainTable.viewMode === 'advanced'
            ? store.mainTable.advancedViewMode.filters.minimumClassroomsDate
            : store.mainTable.simplifiedViewMode.filters.minimumClassroomsDate;
    });
    const maximumClassroomsDate = useWeeklyPlanningPageContext((store) => {
        return store.mainTable.viewMode === 'advanced'
            ? store.mainTable.advancedViewMode.filters.maximumClassroomsDate
            : store.mainTable.simplifiedViewMode.filters.maximumClassroomsDate;
    });
    const setMinimumClassroomsDate = useWeeklyPlanningPageContext((store) => {
        return store.mainTable.viewMode === 'advanced'
            ? store.mainTable.advancedViewMode.filters.setMinimumClassroomsDate
            : store.mainTable.simplifiedViewMode.filters.setMinimumClassroomsDate;
    });
    const setMaximumClassroomsDate = useWeeklyPlanningPageContext((store) => {
        return store.mainTable.viewMode === 'advanced'
            ? store.mainTable.advancedViewMode.filters.setMaximumClassroomsDate
            : store.mainTable.simplifiedViewMode.filters.setMaximumClassroomsDate;
    });
    const setDate = (start, end) => {
        setMaximumClassroomsDate(end);
        setMinimumClassroomsDate(start);
    };
    return (React.createElement(FilterGroup, null,
        React.createElement("span", null, "Per\u00EDodo:"),
        React.createElement("div", null,
            React.createElement(Button, { "$isActive": activeFilter === 'allTime', onClick: () => {
                    setActiveFilter('allTime');
                    setDate(null, null);
                } }, "Todo o tempo"),
            React.createElement(Button, { "$isActive": activeFilter === 'thisMonth', onClick: () => {
                    setActiveFilter('thisMonth');
                    const month = getMonthRange();
                    setDate(month.start, month.end);
                } }, "Este m\u00EAs"),
            React.createElement(Button, { "$isActive": activeFilter === 'thisWeek', onClick: () => {
                    setActiveFilter('thisWeek');
                    const week = getWeekRange();
                    setDate(week.start, week.end);
                } }, "Esta semana"),
            React.createElement(FilterSkillsButton, { isActive: activeFilter === 'customPeriod', setActiveFilter: () => setActiveFilter('customPeriod'), minimumDateFilterValue: minimumClassroomsDate, maximumDateFilterValue: maximumClassroomsDate, onMinimumDateFilterChange: setMinimumClassroomsDate, onMaximumDateFilterChange: setMaximumClassroomsDate }))));
};
export default FilterDate;
