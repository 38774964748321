import { sortPlanningGroups } from '../../../../../common/utils/plannings';
import { createFiltersSliceCreator } from './filters/slice';
import { createOperationsSliceCreator } from './operations/slice';
export function createAdvancedViewModeSlice(events) {
    return (set, get, ...args) => ({
        filteredContent: [],
        nonFilteredContent: [],
        refetchContent: null,
        filters: createFiltersSliceCreator(events)(set, get, ...args),
        operations: createOperationsSliceCreator(events)(set, get, ...args),
        getPlanningGroup(planning) {
            const content = get().mainTable.advancedViewMode.filteredContent;
            return content.find((group) => group.number === planning.associatedGroup);
        },
        setRefetchContent(refetchContent) {
            set((store) => {
                return {
                    mainTable: {
                        ...store.mainTable,
                        advancedViewMode: {
                            ...store.mainTable.advancedViewMode,
                            refetchContent
                        }
                    }
                };
            });
        },
        setContent(content) {
            set((store) => {
                const sorted = sortPlanningGroups(content);
                return {
                    mainTable: {
                        ...store.mainTable,
                        advancedViewMode: {
                            ...store.mainTable.advancedViewMode,
                            filteredContent: sorted,
                            nonFilteredContent: sorted
                        }
                    }
                };
            });
            events.contentSet.emit();
        }
    });
}
