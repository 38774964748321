import React from 'react';
import { AddSkillPopup } from '../../../../../../../../common/components/Popups/AddSkillPopup';
import { useWeeklyPlanningPageContext } from '../../../../../../../context';
import { usePortal } from '../../../../../../../../common/contexts/portals';
import { StyledAddSkillButton } from '../common/styles';
const AddSkillButton = () => {
    const { grades, stages, segments, disciplines, planningStatuses, userPermissions, selectedDisciplines, selectedGrade, selectedSegment, mainTableContent, addPlanning } = useWeeklyPlanningPageContext((store) => ({
        stages: store.commonData.stages.data,
        grades: store.commonData.grades.data,
        segments: store.commonData.segments.data,
        disciplines: store.commonData.disciplines.data,
        planningStatuses: store.commonData.planningStatuses.data,
        userPermissions: store.commonData.userPermissions.data,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines,
        mainTableContent: store.mainTable.advancedViewMode.filteredContent,
        addPlanning: store.mainTable.advancedViewMode.operations.add
    }));
    const { create, destroy } = usePortal(document.body);
    const disabled = !userPermissions?.userCan.updateTheTeacherColumns();
    function onClick() {
        if (disabled)
            return;
        create(React.createElement(AddSkillPopup, { canBeDisplayed: true, mainTableContent: mainTableContent, allStages: stages, allGrades: grades, allSegments: segments, allDisciplines: disciplines, allPlanningStatuses: planningStatuses, selectedGrade: selectedGrade, selectedSegment: selectedSegment, selectedDisciplines: selectedDisciplines, handleExternalClick: destroy, handleCancelButtonClicked: destroy, handleAddSkillSuccess: addPlanning }));
    }
    return (React.createElement(StyledAddSkillButton, { onClick: onClick, disabled: disabled }, "Adicionar Habilidade"));
};
export default AddSkillButton;
