import { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { getAllAreaSkills } from '../../../../../../../services/areaSkill';
export function useFetchAreaSkills(props) {
    const { grade, segment, disciplines } = props;
    const [data, setData] = useState([]);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            if (!(grade && segment && disciplines?.length > 0)) {
                return;
            }
            setRequestStatus(RequestStatus.Loading);
            const response = await getAllAreaSkills({
                abortSignal: abortController.signal,
                gradeId: grade?.id,
                segmentId: segment?.id,
                disciplineId: disciplines?.map((d) => d.id).join(',')
            });
            if (response.data) {
                setData(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
        return () => abortController.abort();
    }, [disciplines, grade, segment]);
    return { data, requestStatus };
}
