import { createStore } from 'zustand';
import { Event } from '@campus/commons';
import { createMainTableSlice } from './slices/mainTable';
import { createCommonDataSlice } from './slices/commonData';
import { createCurriculumComponentsSlice } from './slices/curriculumComponents';
const events = {
    gradesChanged: new Event(),
    segmentsChanged: new Event(),
    disciplinesChanged: new Event()
};
export function createAnnualTimelinePageStore() {
    return createStore()((...args) => ({
        mainTable: createMainTableSlice(...args),
        commonData: createCommonDataSlice(events)(...args),
        curriculumComponents: createCurriculumComponentsSlice(events)(...args)
    }));
}
