import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../context';
import { AddSkillPopup as BasePopup } from '../../../../../../../../../../../common/components/Popups/AddSkillPopup';
import { usePortal } from '../../../../../../../../../../../common/contexts/portals';
export function useControlAddSkillPopup() {
    const { create, destroy } = usePortal(document.body);
    const { stages, grades, segments, disciplines, planningStatuses, selectedGrade, selectedSegment, selectedDisciplines, mainTableContent, addPlanning } = useWeeklyPlanningPageContext((store) => ({
        stages: store.commonData.stages.data,
        grades: store.commonData.grades.data,
        segments: store.commonData.segments.data,
        disciplines: store.commonData.disciplines.data,
        planningStatuses: store.commonData.planningStatuses.data,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines,
        mainTableContent: store.mainTable.advancedViewMode.filteredContent,
        addPlanning: store.mainTable.advancedViewMode.operations.add
    }));
    function createPopup(planning) {
        create(React.createElement(BasePopup, { canBeDisplayed: true, mainTableContent: mainTableContent, allStages: stages, allGrades: grades, allSegments: segments, allDisciplines: disciplines, allPlanningStatuses: planningStatuses, selectedGrade: selectedGrade, selectedSegment: selectedSegment, selectedDisciplines: selectedDisciplines, addPlanningToGroupWithNumber: planning.associatedGroup, handleExternalClick: destroy, handleCancelButtonClicked: destroy, handleAddSkillSuccess: addPlanning }));
    }
    return { createPopup };
}
