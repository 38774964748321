import { useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { getTableContent } from '../../../../../../../services/simplifiedViewModeTable';
import { useWeeklyPlanningPageContext } from '../../../../../context';
export function useFetchSimplifiedViewModeTableContent() {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const mainTableEvents = useWeeklyPlanningPageContext((store) => store.mainTable.events);
    const setTableContent = useWeeklyPlanningPageContext((store) => store.mainTable.simplifiedViewMode.setContent);
    const selectedSegment = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedSegment);
    const selectedGrade = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedGrade);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            setRequestStatus(RequestStatus.NotStarted);
            if (!selectedSegment || !selectedGrade)
                return;
            if (!selectedDisciplines || selectedDisciplines.length === 0)
                return;
            setRequestStatus(RequestStatus.Loading);
            const response = await getTableContent({
                abortSignal: abortController.signal,
                segmentId: selectedSegment.id,
                gradeId: selectedGrade.id,
                disciplineIds: selectedDisciplines.map((d) => d.id)
            });
            if (response.data) {
                setTableContent(response.data);
                setRequestStatus(RequestStatus.Succeeded);
            }
            else if (!response.isCancellation()) {
                setRequestStatus(RequestStatus.Failed);
            }
        }
        fetchData();
        const unsubscribe = mainTableEvents.contentChanged.subscribe(fetchData);
        return () => {
            abortController.abort();
            unsubscribe();
        };
    }, [
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        setTableContent,
        mainTableEvents.contentChanged
    ]);
    return { requestStatus };
}
