import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../context';
import FilterSkill from './components/FilterSkill';
import FilterDate from './components/FilterDate';
import FilterClass from './components/FilterClass';
import VisionSelector from './components/VisionSelector';
import { Container } from './styles';
const FiltersAndSelectors = () => {
    const viewMode = useWeeklyPlanningPageContext((store) => store.mainTable.viewMode);
    return (React.createElement(Container, null,
        viewMode === 'advanced' ? React.createElement(FilterSkill, null) : null,
        React.createElement(FilterDate, null),
        React.createElement(FilterClass, null),
        React.createElement(VisionSelector, null)));
};
export default FiltersAndSelectors;
