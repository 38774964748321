import styled from 'styled-components';
import { TableBodyCell } from '../../styles';
export const Column = styled(TableBodyCell) `
  padding: 10px 12px;
  vertical-align: top;
`;
export const Content = styled.div `
  display: contents;

  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray700};

  .ck.ck-editor__top {
    display: none;
  }

  .ck-content .image,
  .ck-content .image img {
    margin-inline: 0;
  }

  .ck.ck-editor__editable_inline {
    padding: 0;
    overflow: visible;
  }

  .ck.ck-editor__editable_inline p {
    margin: 0;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0;
  }

  .ck.ck-editor__editable_inline > :last-child {
    margin-bottom: 0;
  }

  .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
  .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
    outline: none;
  }

  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border: none;
    border-radius: 0;
  }
`;
