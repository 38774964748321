import styled from 'styled-components';
export const Container = styled.div `
  flex: 1;

  display: grid;

  overflow: hidden;

  margin-bottom: 13px;
  padding-inline: 17px;
`;
export const ScrollableArea = styled.div `
  overflow: auto;
  align-self: stretch;
`;
export const Table = styled.table `
  min-width: 100%;

  border-spacing: 0;
  border-collapse: separate;
`;
export const TableHeader = styled.thead `
  z-index: 5;

  position: sticky;
  top: 0;
  left: 0;
`;
export const TableHeaderRow = styled.tr ``;
export const TableHeaderCell = styled.th `
  height: 32px;

  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.gray600};

  background: ${({ theme }) => theme.colors.gray50};

  border-style: solid;
  border-width: 2px 1px 2px 0;
  border-color: ${({ theme }) => theme.colors.gray300};

  &:last-child {
    border-right-width: 0;
  }

  &:nth-child(1) {
    width: 86px;
    min-width: 86px;
    text-align: center;
  }

  &:nth-child(2) {
    width: 70px;
    min-width: 70px;
    text-align: center;
  }

  &:nth-child(3) {
    width: 81px;
    min-width: 81px;
    text-align: center;
  }

  &:nth-child(4) {
    text-align: left;
    padding-inline: 12px;
  }
`;
export const TableBody = styled.tbody `
  &::before {
    content: '';
    height: 10px;
    display: block;
  }
`;
export const TableBodyRowSpacer = styled.tr `
  height: 14px;
`;
export const TableBodyRow = styled.tr.attrs((props) => ({
    className: (props.$isTheFirstRowInGroup ? 'first-in-group' : '') +
        (props.$isTheLastRowInGroup ? ' last-in-group' : '') +
        (props.$isInTheFirstActivity ? ' in-the-first-activity' : '') +
        (props.$isInTheLastActivity ? ' in-the-last-activity' : '')
})) `
  & > .first-cell {
    border-left-width: 1px;
  }

  &.first-in-group > .cell {
    border-top-width: 1px;
  }

  &.first-in-group > .first-cell {
    border-top-left-radius: 4px;
  }

  &.last-in-group > .first-cell {
    border-bottom-left-radius: 4px;
  }

  &.in-the-first-activity > .last-cell {
    border-top-right-radius: 4px;
  }

  &.in-the-last-activity > .last-cell {
    border-bottom-right-radius: 4px;
  }
`;
export const TableBodyCell = styled.td.attrs({
    className: 'cell'
}) `
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: ${({ theme }) => theme.colors.gray200};
`;
