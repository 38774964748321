import { createStore } from 'zustand';
import { RequestStatus } from '../../../../../types/dataFetching';
import { createClassesAndInfos } from './helpers';
export function createAddOrEditActivityPopupStore(params) {
    return createStore()((set, get) => ({
        abortSignal: params.abortSignal,
        planning: params.planning,
        sequence: params.sequence,
        anImageIsBeingUploaded: false,
        impactTheMDC: params.sequence?.impactTheMDC,
        sequenceDescription: params.sequence?.description ?? '',
        selectedEvaluationType: params.sequence?.evaluationType ?? null,
        evaluationTypes: { data: [], status: RequestStatus.NotStarted },
        classesAndInfos: {
            status: RequestStatus.NotStarted,
            data: new Map()
        },
        setImpactTheMDC(impactTheMDC) {
            set((store) => ({ ...store, impactTheMDC }));
        },
        setSequenceDescription(sequenceDescription) {
            set((store) => ({ ...store, sequenceDescription }));
        },
        setSelectedEvaluationType(selectedEvaluationType) {
            set((store) => ({ ...store, selectedEvaluationType }));
        },
        setEvaluationTypes(evaluationTypes, status) {
            set((store) => {
                return {
                    ...store,
                    evaluationTypes: {
                        status,
                        data: evaluationTypes
                    }
                };
            });
        },
        setAnImageIsBeingUploaded(anImageIsBeingUploaded) {
            set((store) => ({ ...store, anImageIsBeingUploaded }));
        },
        setClassesAndInfos(groups, status) {
            set((store) => {
                const classesAndInfos = {
                    status,
                    data: store.classesAndInfos.data
                };
                if (groups) {
                    classesAndInfos.data = createClassesAndInfos({
                        classroomGroups: groups,
                        existingSequence: store.sequence
                    });
                }
                return { ...store, classesAndInfos };
            });
        },
        getAllSelectedClassrooms() {
            const { classesAndInfos } = get();
            const selectedClassrooms = [];
            for (const infos of classesAndInfos.data.values()) {
                if (infos.isChecked) {
                    selectedClassrooms.push(infos.selectedClassroom);
                }
            }
            return selectedClassrooms;
        },
        updateClassInfos(className, updates) {
            set((store) => {
                const oldRunningFetch = store.classesAndInfos;
                const oldMap = oldRunningFetch.data;
                const newMap = new Map(oldMap);
                const oldInfos = oldMap.get(className);
                const newInfos = { ...oldInfos, ...updates };
                newMap.set(className, newInfos);
                const newRunningFetch = { ...oldRunningFetch, data: newMap };
                return { ...store, classesAndInfos: newRunningFetch };
            });
        },
        updateSelectedClassroomForEveryClass(classroomNumber) {
            set((store) => {
                const oldRunningFetch = store.classesAndInfos;
                const oldMap = oldRunningFetch.data;
                const newMap = new Map(oldMap);
                for (const [className, infos] of oldMap) {
                    const classroom = infos.allClassrooms.find((c) => {
                        return c.number === classroomNumber;
                    });
                    if (classroomNumber === null || classroomNumber === undefined) {
                        newMap.set(className, { ...infos, selectedClassroom: null });
                    }
                    else if (classroom) {
                        newMap.set(className, { ...infos, selectedClassroom: classroom });
                    }
                }
                const newRunningFetch = { ...oldRunningFetch, data: newMap };
                return { ...store, classesAndInfos: newRunningFetch };
            });
        }
    }));
}
