import { applyFilters } from './filters';
export function updateSlice(store, updates) {
    const filters = {
        ...store.mainTable.advancedViewMode.filters,
        ...updates
    };
    const filteredContent = applyFilters(store.mainTable.advancedViewMode.nonFilteredContent, filters);
    return {
        mainTable: {
            ...store.mainTable,
            advancedViewMode: {
                ...store.mainTable.advancedViewMode,
                filters,
                filteredContent
            }
        }
    };
}
