import React from 'react';
import { useWeeklyPlanningPageContext } from '../../context';
import BaseSubtitle from '../../../common/components/PageRelated/Subtitle/';
export default function Subtitle() {
    const evaluationTypes = useWeeklyPlanningPageContext((store) => {
        return store.commonData.evaluationTypes.data;
    });
    return (React.createElement(BaseSubtitle, { content: [
            'used',
            'unnused',
            'evaluativeObjective',
            evaluationTypes,
            'br',
            'skillOfOtherComponent',
            'plannedButDeletedSkill',
            'editionAndRemotionBlocked',
            'evaluative'
        ] }));
}
