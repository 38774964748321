import SimplifiedViewModeTableActivity from '../../../../../../../models/SimplifiedViewModeTableActivity';
import SimplifiedViewModeTablePlanningGroup from '../../../../../../../models/SimplifiedViewModeTablePlanningGroup';
import { compareDatesWithoutTime } from '../../../../../../common/utils/dates';
function applyClassFilter(classId, content) {
    if (!classId)
        return content;
    const newContent = [];
    for (const planning of content) {
        let newGroup = null;
        for (const activity of planning.didacticSequenceActivities) {
            const skipActivity = !activity.classrooms.some((c) => c.class.id === classId);
            if (skipActivity)
                continue;
            if (!newGroup) {
                newGroup = new SimplifiedViewModeTablePlanningGroup();
                newGroup.number = planning.number;
                newGroup.didacticSequenceActivities = [];
            }
            const newActivity = new SimplifiedViewModeTableActivity();
            newActivity.id = activity.id;
            newActivity.content = activity.content;
            newActivity.classrooms = activity.classrooms.filter((c) => c.class.id === classId);
            newGroup.didacticSequenceActivities.push(newActivity);
        }
        if (newGroup)
            newContent.push(newGroup);
    }
    return newContent;
}
function applyClassroomDateFilter(content, classroomsDateShouldBe, date) {
    if (!date)
        return content;
    const newContent = [];
    for (const planning of content) {
        let newGroup = null;
        for (const activity of planning.didacticSequenceActivities) {
            const skipActivity = !activity.classrooms.some((c) => {
                return classroomsDateShouldBe === 'lessThan'
                    ? compareDatesWithoutTime(c.date, date) <= 0
                    : compareDatesWithoutTime(c.date, date) >= 0;
            });
            if (skipActivity)
                continue;
            if (!newGroup) {
                newGroup = new SimplifiedViewModeTablePlanningGroup();
                newGroup.number = planning.number;
                newGroup.didacticSequenceActivities = [];
            }
            const newActivity = new SimplifiedViewModeTableActivity();
            newActivity.id = activity.id;
            newActivity.content = activity.content;
            newActivity.classrooms = activity.classrooms.filter((c) => {
                return classroomsDateShouldBe === 'lessThan'
                    ? compareDatesWithoutTime(c.date, date) <= 0
                    : compareDatesWithoutTime(c.date, date) >= 0;
            });
            newGroup.didacticSequenceActivities.push(newActivity);
        }
        if (newGroup)
            newContent.push(newGroup);
    }
    return newContent;
}
function applyMinimumClassroomDateFilter(date, content) {
    return applyClassroomDateFilter(content, 'greaterThan', date);
}
function applyMaximumClassroomDateFilter(date, content) {
    return applyClassroomDateFilter(content, 'lessThan', date);
}
export function applyFilters(mainTableContent, slice) {
    let newContent = mainTableContent;
    newContent = applyClassFilter(slice.selectedClass?.id, newContent);
    newContent = applyMinimumClassroomDateFilter(slice.minimumClassroomsDate, newContent);
    newContent = applyMaximumClassroomDateFilter(slice.maximumClassroomsDate, newContent);
    return newContent;
}
