import { useWeeklyPlanningPageContext } from '../../../../../../../../../../context';
export function useMovePlanning() {
    const movePlanning = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.operations.move);
    const tableContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filteredContent);
    function isThereMoreThanOnePlanningInTable() {
        let count = 0;
        for (const group of tableContent) {
            count += group.plannings.length;
            if (count > 1)
                return true;
        }
        return count > 1;
    }
    function movePlanningUp(planning) {
        return new Promise((resolve, reject) => {
            if (isThereMoreThanOnePlanningInTable()) {
                movePlanning(planning, 'up');
                resolve();
            }
            else {
                reject();
            }
        });
    }
    function movePlanningDown(planning) {
        return new Promise((resolve, reject) => {
            if (isThereMoreThanOnePlanningInTable()) {
                movePlanning(planning, 'down');
                resolve();
            }
            else {
                reject();
            }
        });
    }
    return { movePlanningUp, movePlanningDown };
}
