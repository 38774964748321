const prefix = '@campus:curriculumComponents:';
const keys = {
    grade: prefix + 'lastSelectedGrade',
    segment: prefix + 'lastSelectedSegment',
    disciplines: prefix + 'lastSelectedDisciplines'
};
function saveToStorage(key, value) {
    sessionStorage.setItem(keys[key], value);
}
function getFromStorage(key) {
    return sessionStorage.getItem(keys[key]);
}
export function segmentIdInStorage() {
    return getFromStorage('segment');
}
export function saveSegmentToStorage(segmentId) {
    saveToStorage('segment', segmentId ?? null);
}
export function gradeIdInStorage() {
    return getFromStorage('grade');
}
export function saveGradeToStorage(gradeId) {
    saveToStorage('grade', gradeId);
}
export function disciplineIdsInStorage() {
    const serializedIds = getFromStorage('disciplines');
    if (!serializedIds)
        return [];
    return JSON.parse(serializedIds);
}
export function saveDisciplinesToStorage(disciplines) {
    const ids = disciplines.map((d) => d.id);
    saveToStorage('disciplines', JSON.stringify(ids));
}
