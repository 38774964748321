import { useEffect, useState } from 'react';
export function useControlLastColumnRightBorder({ getContainerElement }) {
    const [removeBorder, setRemoveBorder] = useState(false);
    useEffect(() => {
        const container = getContainerElement();
        if (!container)
            return;
        const observer = new ResizeObserver(() => {
            setRemoveBorder(container.scrollWidth > container.clientWidth);
        });
        observer.observe(container, { box: 'border-box' });
        return () => observer.disconnect();
    }, [getContainerElement]);
    return { removeBorder };
}
