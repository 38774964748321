import { applyFilters } from './filters';
export function updateSlice(store, updates) {
    const filters = {
        ...store.mainTable.simplifiedViewMode.filters,
        ...updates
    };
    const filteredContent = applyFilters(store.mainTable.simplifiedViewMode.nonFilteredContent, filters);
    return {
        mainTable: {
            ...store.mainTable,
            simplifiedViewMode: {
                ...store.mainTable.simplifiedViewMode,
                filters,
                filteredContent
            }
        }
    };
}
