import { createSimplifiedViewModeSlice } from './simplifiedViewMode/slice';
import { createAdvancedViewModeSlice } from './advancedViewMode/slice';
export function createMainTableSlice(events) {
    return (set, ...args) => ({
        events,
        viewMode: 'advanced',
        setViewMode(viewMode) {
            set((store) => ({ mainTable: { ...store.mainTable, viewMode } }));
        },
        advancedViewMode: createAdvancedViewModeSlice(events)(set, ...args),
        simplifiedViewMode: createSimplifiedViewModeSlice(events)(set, ...args)
    });
}
