import React, { useState } from 'react';
import AddActivityPopup from '../../../../../../../../Popups/AddActivityPopup';
import { StyledButton } from './styles';
const AddActivityButton = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    function onClick() {
        if (!props.disabled) {
            setCanShowPopup(true);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledButton, { onClick: onClick, disabled: props.disabled }, "Adicionar atividade"),
        React.createElement(AddActivityPopup, { canBeDisplayed: canShowPopup, planning: props.planning, disciplines: props.disciplines, classroomGroups: props.classroomGroups, newActivityIsUnforeseen: props.newActivityIsUnforeseen, hide: () => setCanShowPopup(false), handleActivityAdded: props.handleActivityAdded })));
};
export default AddActivityButton;
