import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../context';
import TablePlaceholder from '../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import AdvancedViewModeTable from './components/AdvancedViewModeTable';
import SimplifiedViewModeTable from './components/SimplifiedViewModeTable';
import { getPlaceholder } from './placeholders';
import { useFetchAdvancedViewModeTableContent, useFetchSimplifiedViewModeTableContent } from './hooks';
const Table = () => {
    const viewMode = useWeeklyPlanningPageContext((store) => store.mainTable.viewMode);
    const selectedSegment = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedSegment);
    const selectedGrade = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedGrade);
    const selectedDisciplines = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines);
    const advancedViewModeTable = useFetchAdvancedViewModeTableContent();
    const simplifiedViewModeTable = useFetchSimplifiedViewModeTableContent();
    const fetchContentRequestStatus = viewMode === 'advanced'
        ? advancedViewModeTable.requestStatus
        : simplifiedViewModeTable.requestStatus;
    const placeholder = getPlaceholder({
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        fetchContentRequestStatus
    });
    if (placeholder) {
        return React.createElement(TablePlaceholder, { content: placeholder });
    }
    return viewMode === 'advanced' ? (React.createElement(AdvancedViewModeTable, null)) : (React.createElement(SimplifiedViewModeTable, null));
};
export default Table;
