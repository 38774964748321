import { RequestStatus } from '../../../types/dataFetching';
export function createBaseCommonDataSlice(events, updateSlice) {
    return {
        grades: { data: [], status: RequestStatus.NotStarted },
        segments: { data: [], status: RequestStatus.NotStarted },
        disciplines: { data: [], status: RequestStatus.NotStarted },
        setSegments(updates) {
            updateSlice((state) => {
                return { segments: { ...state.segments, ...updates } };
            });
            events.segmentsChanged.emit();
        },
        setGrades(updates) {
            updateSlice((store) => {
                return { grades: { ...store.grades, ...updates } };
            });
            events.gradesChanged.emit();
        },
        setDisciplines(updates) {
            updateSlice((store) => {
                return { disciplines: { ...store.disciplines, ...updates } };
            });
            events.disciplinesChanged.emit();
        }
    };
}
